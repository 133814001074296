import React, { FC } from 'react'
import festivalInfo from '../../data/festivalInfo'
import settings from '../../data/settings'
const information = festivalInfo.find((info) => info.yearString === settings.activeYear)

const Info: FC = () => (
  <section>
    <h2>Information</h2>
    <p>{information?.infoText}</p>
  </section>
)

export default Info
