import React, { PureComponent } from 'react'
import {
  Header,
  Artists,
  Lineup,
  Info,
  Contact,
  Footer,
  TicketLink,
  SpotifyWidget,
  SoundCloudWidget,
  FacebookWidget,
  ArtistHistory,
  Schedule,
} from './Components'
import { text } from './data'
import { getCurrent, isThePartyOver } from './utils'
import styles from './App.module.scss'

const parseWelcomeText = () =>
  text.welcomeText.replace('--VENUE--', getCurrent('venue')).replace('--DATE--', getCurrent('monthAndDayStringV2'))

const parseTicketText = (string, link = null) => {
  const stringWithoutHashes = string.replace(/###/g, '')
  const splitString = string.split('###')

  if (!link) {
    return stringWithoutHashes
  }

  return (
    <>
      <span>{splitString[0] && splitString[0]}</span>
      <a href={link} rel="noopener noreferrer" target="_blank">
        {splitString[1] && splitString[1]}
      </a>
      <span>{splitString[2] && splitString[2]}</span>
    </>
  )
}

class App extends PureComponent {
  ticketWrapperRef = React.createRef()
  state = {
    spotifyId: null,
    soundCloudId: null,
  }

  handleSpotifyClick = (spotifyId) => {
    this.setState({ spotifyId, soundCloudId: null })
  }

  handleSoundCloudClick = (soundCloudId) => {
    this.setState({ soundCloudId, spotifyId: null })
  }

  handleCloseWidget = () => {
    this.setState({ spotifyId: null, soundCloudId: null })
  }

  componentDidMount() {
    if (!this.ticketWrapperRef.current) {
      return
    }

    setTimeout(() => {
      this.ticketWrapperRef.current.className = `${this.ticketWrapperRef.current.className} ${styles.slideIn}`
    }, 1000)
  }

  render() {
    const { soundCloudId, spotifyId } = this.state
    const isFestivalOver = getCurrent('festivalOver') ?? isThePartyOver()
    const ticketLink = getCurrent('ticketLink')
    const isSoldOut = getCurrent('soldOut')

    const { thankYouText, ticketText, ticketTextMobile, soldOutText, ticketsNotReleasedText } = text

    const infoText = getCurrent('infoText')

    return (
      <div className={styles.appContainer}>
        <Header />
        <Lineup />
        {isFestivalOver && <p className={styles.thankYouText}>{thankYouText}</p>}
        {!isFestivalOver && (
          <>
            <p className={styles.welcomeText}>{parseWelcomeText()}</p>
            {ticketLink && !isSoldOut && (
              <>
                <p className={styles.ticketText}>{parseTicketText(ticketText, ticketLink)}</p>
                <p className={styles.ticketTextMobile}>{parseTicketText(ticketTextMobile, ticketLink)}</p>
              </>
            )}
            {isSoldOut && (
              <>
                <h2 className={styles.soldOutHeading}>SOLD OUT!</h2>
                <p className={styles.soldOutText}>{soldOutText}</p>
              </>
            )}
            {!ticketLink && <p className={styles.ticketsNotReleasedText}>{ticketsNotReleasedText}</p>}
          </>
        )}
        <Schedule />
        <Artists soundCloudClick={this.handleSoundCloudClick} spotifyClick={this.handleSpotifyClick} />
        <div className={styles.infoWrapper}>
          {!!infoText && (
            <div className={styles.infoWrapperChildren}>
              <Info />
              {/* <About /> */}
            </div>
          )}
          <div className={styles.infoWrapperChildren}>
            <FacebookWidget />
          </div>
        </div>
        <ArtistHistory />
        <Contact />
        <Footer />
        {ticketLink && !isSoldOut && !isFestivalOver && (
          <TicketLink ticketLink={ticketLink} ticketWrapperRef={this.ticketWrapperRef} />
        )}
        {spotifyId && <SpotifyWidget closeWidget={this.handleCloseWidget} spotifyId={spotifyId} />}
        {soundCloudId && <SoundCloudWidget closeWidget={this.handleCloseWidget} soundCloudId={soundCloudId} />}
      </div>
    )
  }
}

export default App
