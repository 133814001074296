export default [
  {
    yearString: '2018',
    monthAndDayString: 'October 6',
    monthAndDayStringV2: '6th of October',
    timeString: '16:30',
    dateString: '2018-10-06',
    venue: 'Nalen',
    ticketLink: '',
    lineupComplete: true,
    festivalOver: true,
    soldOut: false,
  },
  {
    yearString: '2019',
    monthAndDayString: 'October 5',
    monthAndDayStringV2: '5th of October',
    dateString: '2019-10-05',
    timeString: '16:30',
    venue: 'Nalen',
    ticketLink: 'https://secure.tickster.com/644wpm0a2v771he?t=n7ttwdyf',
    lineupComplete: true,
    festivalOver: true,
    soldOut: true,
  },
  {
    yearString: '2020',
    monthAndDayString: 'October 3',
    monthAndDayStringV2: '3rd of October',
    dateString: '2019-10-03',
    timeString: '16:30',
    venue: 'Nalen',
    ticketLink: 'https://www.tickster.com/sv/events/8x5dmu355yzev5f/2020-10-03/bodyfest',
    lineupComplete: false,
    festivalOver: false,
    soldOut: false,
  },
  {
    yearString: '2021',
    monthAndDayString: 'October 9',
    monthAndDayStringV2: '9th of October',
    dateString: '2021-10-09',
    timeString: '18:00',
    venue: 'Nalen',
    ticketLink: 'https://tickets.blivande.com/on/bodyfest',
    lineupComplete: true,
    festivalOver: true,
    soldOut: false,
  },
  {
    yearString: '2022',
    monthAndDayString: 'October 8',
    monthAndDayStringV2: '8th of October',
    dateString: '2022-10-08',
    timeString: '18:00',
    venue: 'Nalen',
    ticketLink: 'https://secure.tickster.com/aefhcrrae2tc2pv',
    lineupComplete: true,
    festivalOver: false,
    soldOut: false,
  },
  {
    yearString: '2023',
    monthAndDayString: 'September 30',
    monthAndDayStringV2: '30th of September',
    dateString: '2023-09-30',
    timeString: '17:00',
    venue: 'Nalen',
    ticketLink: 'https://secure.tickster.com/wnby3p8t1ldwwut',
    lineupComplete: true,
    festivalOver: true,
    soldOut: false,
  },
  {
    yearString: '2024',
    monthAndDayString: 'October 12',
    monthAndDayStringV2: '12th of October',
    dateString: '2024-10-12',
    timeString: '18:00',
    venue: 'Kollektivet Livet',
    ticketLink: 'https://www.tickster.com/se/sv/events/22rlumw9apwl4xg/2024-10-12/bodyfest-kollektivet-livet-stockholm',
    lineupComplete: true,
    festivalOver: true    ,
    soldOut: false,
  },
  {
    yearString: '2025',
    monthAndDayString: 'October 11',
    monthAndDayStringV2: '11th of October',
    dateString: '2024-10-11',
    timeString: '17:00',
    venue: 'Kollektivet Livet',
    ticketLink: 'https://www.tickster.com/se/sv/events/dhwh6p9y4ynwg6d/2025-10-11/bodyfest-2025',
    lineupComplete: false,
    festivalOver: false,
    soldOut: false,
    infoText: null
  },
];
