import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Artist from '../Artist/Artist'
import ArtistModal from '../Artist/ArtistModal'
import Modal from '../Modal/Modal'
import { getCurrentArtists, headlineSort } from '../../utils'
import styles from './Artists.module.scss'

const Artists = ({ spotifyClick, soundCloudClick }) => {
  const [showModalArtist, setShowModalArtist] = useState(false)

  const currentArtists = getCurrentArtists()
    .filter((artist) => !!artist.description && !!artist.image)
    .sort(headlineSort)

  const openModal = (artistId) => {
    document.body.classList.add('fixedBody')
    setShowModalArtist(artistId)
  }

  const closeModal = () => {
    document.body.classList.remove('fixedBody')
    setShowModalArtist(null)
  }

  return (
    <div className={styles.artists}>
      {currentArtists.map((artist) => {
        const { description, name, image, id, subtitle, countryCode, spotify, soundCloud, links } = artist

        const modalBody = (
          <ArtistModal
            key={`${id}-modalContent`}
            countryCode={countryCode}
            description={description}
            id={id}
            image={image}
            name={name}
            soundCloud={soundCloud}
            spotify={spotify}
            subtitle={subtitle}
          />
        )

        const showReadMore = description.length > 1

        return (
          <Fragment key={`${id}-fragment`}>
            {showModalArtist === id && (
              <Modal key={`${id}-modal`} artistId={id} body={modalBody} closeModal={closeModal} />
            )}
            <Artist
              key={id}
              countryCode={countryCode}
              description={description[0]}
              id={id}
              image={image}
              name={name}
              openModal={openModal}
              showReadMore={showReadMore}
              soundCloud={soundCloud}
              soundCloudClick={soundCloudClick}
              spotify={spotify}
              spotifyClick={spotifyClick}
              subtitle={subtitle}
              links={links}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

Artists.propTypes = {
  soundCloudClick: PropTypes.func,
  spotifyClick: PropTypes.func,
}

export default Artists
